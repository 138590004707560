.Calendar__day.-selectedBetween{
    background: #ff33333b !important;
    color: #000 !important;
}

.rotate-90 {
    transform: rotate(90deg);
}

@font-face {
    font-family: 'selfi_regular';
    src: url('./assets/fonts/Selfie_Regular.otf') format("opentype");
}
@font-face {
    font-family: 'dense_regular';
    src: url('./assets/fonts/Dense-Regular.otf') format("opentype");
}